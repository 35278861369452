import React from "react";
import { useTranslation } from "react-i18next";
import { LuLineChart } from "react-icons/lu";
import { LuBarChartHorizontal } from "react-icons/lu";
import { Outlet, useLocation, useParams } from "react-router-dom";

import {
  Cog6ToothIcon,
  QueueListIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "../../../../api/solutions";
import Sidebar, {
  CustomIcon,
  SidebarItem,
} from "../../../../components/sidebar/Sidebar";
import BasicSkeleton from "../../../../components/skeletons/BasicSkeleton";
import SettingsSkeleton from "../../../../components/skeletons/SettingsSkeleton";
import { getAnalysisPath, getAnalyzerPath } from "../../routes/analyzer";
import { getDailyProfilesPath } from "../../routes/dailyProfiles";
import { getOverviewPath } from "../../routes/overview";
import { getSettingsPath } from "../../routes/settings";
import { getTimeSeriesPath } from "../../routes/timeSeries";
import { getWeeklyProfilesPath } from "../../routes/weeklyProfiles";
import { getYearsComparisonPath } from "../../routes/yearsComparison";
import AnalysisSkeleton from "../AnalysisSkeleton";

interface AnalyzerLayoutProps {
  children?: React.ReactNode;
}

export default function AnalyzerLayout({ children }: AnalyzerLayoutProps) {
  const [{ data: solution }, solutionId] = useSolution();

  const analyzerItems = useAnalyzerItems(solutionId);
  const analysisItems = useAnalysisItems(solutionId);

  const skeleton = useSkeleton();

  return (
    <Sidebar
      topNavigation={[analyzerItems, analysisItems]}
      solution={solution}
      //forceCollapsed={!!analysisId}
    >
      <main className="grow p-4 flex flex-col">
        <React.Suspense fallback={skeleton}>
          <Outlet />
          {children}
        </React.Suspense>
      </main>
    </Sidebar>
  );
}

function useAnalyzerItems(solutionId: string): SidebarItem[] {
  const location = useLocation();
  const { t } = useTranslation();

  const items: SidebarItem[] = [
    {
      key: "analyses",
      name: t("Change analysis"),
      href: getAnalyzerPath(solutionId),
      icon: QueueListIcon,
      current: location.pathname === getAnalyzerPath(solutionId),
    },
  ];

  return items;
}

function useAnalysisItems(solutionId: string): SidebarItem[] {
  const { analysisId } = useParams();
  const location = useLocation();
  const { t } = useTranslation();

  if (!analysisId) {
    return [];
  }

  const items: SidebarItem[] = [
    {
      key: "heatmap",
      name: t("Heatmap"),
      href: getAnalysisPath(solutionId, analysisId) + window.location.search,
      icon: ((props) => (
        <LuBarChartHorizontal {...props} />
      )) satisfies CustomIcon,
      current: location.pathname === getAnalysisPath(solutionId, analysisId),
    },
    {
      key: "overview",
      name: t("Summary statistics"),
      href: getOverviewPath(solutionId, analysisId) + window.location.search,
      icon: TableCellsIcon,
      current: location.pathname === getOverviewPath(solutionId, analysisId),
    },
    {
      key: "timeSeries",
      name: t("Time series"),
      href: getTimeSeriesPath(solutionId, analysisId) + window.location.search,
      icon: ((props) => <LuLineChart {...props} />) satisfies CustomIcon,
      current: location.pathname === getTimeSeriesPath(solutionId, analysisId),
    },
    {
      key: "yearsComparison",
      name: t("Years comparison"),
      href:
        getYearsComparisonPath(solutionId, analysisId) + window.location.search,
      icon: ((props) => <LuLineChart {...props} />) satisfies CustomIcon,
      current:
        location.pathname === getYearsComparisonPath(solutionId, analysisId),
    },
    {
      key: "weeklyProfiles",
      name: t("Weekly profiles"),
      href:
        getWeeklyProfilesPath(solutionId, analysisId) + window.location.search,
      icon: ((props) => <LuLineChart {...props} />) satisfies CustomIcon,
      current:
        location.pathname === getWeeklyProfilesPath(solutionId, analysisId),
    },
    {
      key: "dailyProfiles",
      name: t("Daily profiles"),
      href:
        getDailyProfilesPath(solutionId, analysisId) + window.location.search,
      icon: ((props) => <LuLineChart {...props} />) satisfies CustomIcon,
      current:
        location.pathname === getDailyProfilesPath(solutionId, analysisId),
    },
    {
      key: "analysisSettings",
      name: t("Analysis settings"),
      href: getSettingsPath(solutionId, analysisId) + window.location.search,
      icon: Cog6ToothIcon,
      current: location.pathname === getSettingsPath(solutionId, analysisId),
    },
  ];

  return items;
}

// TODO use function paths instead
function useSkeleton(): JSX.Element {
  const location = useLocation();

  // analysis settings page
  if (location.pathname.match(/\/auth\/\d+\/analyzer\/\d+\/settings/)) {
    return <SettingsSkeleton />;
  }

  // analysis chart page
  if (location.pathname.match(/\/auth\/\d+\/analyzer\/\d+/)) {
    return <AnalysisSkeleton pageTitle="" />;
  }

  return <BasicSkeleton />;
}
